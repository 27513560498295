import { constructQueryParams } from "./utils";

const baseurl = process.env.REACT_APP_URL;


export type ReturnPayload<T> = {
  status: number
  data?: T
  err?: string
}

/**
 * Fetch and return data as a promise
 * @param url the api path, without base url
 * @param init the parameters for the request
 * @returns a promise with the payload
 */
export async function promiseFetch<T>(url: string, init: RequestInit = {}): Promise<ReturnPayload<T>> {
  return fetch(baseurl + url, { credentials: 'include', ...init })
    .then(async res => {
      const status = res.status;
      const err = res.ok ? "" : (await res.text().catch(() => ""));
      const data = res.ok ? await res.json().catch(() => null) : null;
      
      return { status, data: (data === null ? undefined : data), err: (err === "" ? undefined : err) };
    });
}

/**
 * Default GET query with url and params
 * @param url the api path, without base url
 * @param params the query params for the request
 * @returns a promise with the data
 */
export async function defaultGetQuery<T>(url: string, params?: {[key: string]: any}): Promise<T[] | undefined> {
  if (params)
    url = url+'?'+constructQueryParams(params);

  return promiseFetch<T[]>(url, {
    method: 'GET',
    headers: { "Content-Type": "application/json; charset=utf-8" }
  })
  .then(res => {
    if (res.status >= 400)
      throw new Error(res.err);
    return res.data;
  })
}
