import { TEventStatus, TOrderStatus, TWebsiteStatus } from '../../../src/models/types/types';

export const websiteStatusName: { [key in TWebsiteStatus]: string } = {
  // "default": "Normal",
  // "maintenance": "Maintenance",
  "default": "Default",
  "maintenance": "Maintenance",
}

export const artistInputStatusName: { [k: string]: string } = {
  // "sent": "Envoyé",
  // "confirmed": "Confirmé",
  // "cancelled": "Annulé",
  // "awaiting": "En attente",
  "sent": "Sent",
  "confirmed": "Confirmed",
  "cancelled": "Cancelled",
  "awaiting": "Awaiting",
};

export const eventStatusName: { [key in TEventStatus]: string } = {
  // "ongoing": "Actuellement",
  // "coming": "Prochainement",
  // "finished": "Terminé",
  // "cancelled": "Annulé"
  "ongoing": "Ongoing",
  "coming": "Coming",
  "finished": "Finished",
  "cancelled": "Cancelled"
};

export const orderStatusName: { [key in TOrderStatus]: string } = {
  // "awaitingPayment": "En attente de paiement",
  // "awaitingConfirmation": "En attente de confirmation",
  // "processing": "En cours d'envoi",
  // "sent": "Envoyé",
  // "cancelled": "Annulé"
  "awaitingPayment": "Awaiting payment",
  "awaitingConfirmation": "Awaiting confirmation",
  "processing": "Processing",
  "sent": "Sent",
  "cancelled": "Cancelled"
};

export const EMPTY_UUID = "00000000-0000-0000-0000-000000000000";
export const ORDER_TIMEOUT = (6*24+22)*60*60*1000; // 6 days and 22 hours (to be conservative)
