import React from 'react'
import { TPayload } from 'src/utils/form';
import { promiseFetch, ReturnPayload } from 'src/utils/request';

type TStatus = {
  isadmin: boolean
  isartist: boolean
  isreset: boolean
  artistid: string | null
};

type TReturnStatus = TStatus | string;

type TReturn = boolean | string;

type TUserState = {
  isauth: boolean
  isadmin: boolean
  isartist: boolean
  isreset: boolean
  isloading: boolean
  artistid: string | null
};

type TWebsiteState = {
  ismaintenance: boolean
  isloading: boolean
};


async function getWebsiteStatus() {
  return promiseFetch<{ ismaintenance: boolean }>(`/api/misc/status`);
}

async function getStatus() {
  return promiseFetch<TReturnStatus>(`/api/users/status`);
}

async function authLogin(payload: TPayload) {
  return promiseFetch<TReturnStatus>(`/api/users/login`, {
    method: 'POST',
    credentials: 'include',
    headers: { "Content-Type": "application/json; charset=utf-8" },
    body: JSON.stringify(payload)
  });
}

async function authSignup(payload: TPayload) {
  return promiseFetch<TReturnStatus>(`/api/users/signup`, {
    method: 'POST',
    credentials: 'include',
    headers: { "Content-Type": "application/json; charset=utf-8" },
    body: JSON.stringify(payload)
  });
}

async function authLogout() {
  return promiseFetch<TReturn>(`/api/users/logout`);
}

async function authModify(payload: TPayload) {
  return promiseFetch<TReturnStatus>(`/api/users/modifycurrent`, {
    method: 'PATCH',
    credentials: 'include',
    headers: { "Content-Type": "application/json; charset=utf-8" },
    body: JSON.stringify(payload)
  });
}

export async function authResetPassword(payload: TPayload) {
  return promiseFetch<TReturn>(`/api/users/resetpassword`, {
    method: 'POST',
    headers: { "Content-Type": "application/json; charset=utf-8" },
    body: JSON.stringify(payload)
  });
}

async function authDeleteAccount() {
  return promiseFetch<TReturn>(`/api/users/deletecurrent`, {
    method: 'DELETE',
  });
}


interface AuthContextComponentInterface {
  children: React.ReactNode | React.ReactNode[]
}


export const AuthContext = React.createContext<Partial<TAuthContextProvider>>({});


export function AuthContextComponent({ children }: AuthContextComponentInterface) {
  const [userState, setUserState] = React.useState<TUserState>({
    isauth: false,
    isadmin: false,
    isartist: false,
    isreset: false,
    isloading: true,
    artistid: null
  });
  const [websiteState, setWebsiteState] = React.useState<TWebsiteState>({
    ismaintenance: false,
    isloading: true,
  });

  React.useEffect(() => {
    checkStatus();
    checkWebsiteStatus();
  }, []);


  const checkStatus = async () => {
    return getStatus()
      .then(res => {
        setUserState(userState => {
          let modifiedState = {};
          if (res.status===200 && isStatus(res.data)) {
            const status = (res.data) as TStatus;
            modifiedState = { isauth: true, ...status };
          }
          return { ...userState, ...modifiedState, isloading: false };
        });
        return res;
      })
  }

  const checkWebsiteStatus = async () => {
    return getWebsiteStatus()
      .then(res => {
        setWebsiteState(websiteState => {
          let modifiedState = {};
          if (res.status===200 && res.data) {
            modifiedState = res.data;
          }
          return { ...websiteState, ...modifiedState, isloading: false };
        });
        return res;
      })
  }

  const login = async (payload: TPayload, onSuccess?: Function) => {
    return authLogin(payload)
      .then(res => {
        if (res.status===200)
          onSuccess?.();
        setUserState(userState => {
          return {
            ...userState,
            isauth: (res.status===200),
            isartist: ((isStatus(res.data) && res.data.isartist) || false),
            isadmin: ((isStatus(res.data) && res.data.isadmin) || false),
            isreset: ((isStatus(res.data) && res.data.isreset) || false),
            artistid: ((isStatus(res.data) && res.data.artistid) || null)
          };
        });
        return res;
      });
  };

  const signup = async (payload: TPayload, onSuccess?: Function) => {
    return authSignup(payload)
    .then(res => {
      if (res.status===200)
        onSuccess?.();
      setUserState(userState => {
        return {
          ...userState,
          isauth: (res.status===200),
          isartist: ((isStatus(res.data) && res.data.isartist) || false),
          isadmin: ((isStatus(res.data) && res.data.isadmin) || false),
          artistid: ((isStatus(res.data) && res.data.artistid) || null)
        };
      });
      return res;
    });
  };

  const logout = async () => {
    return authLogout()
      .then(res => {
        setUserState(userState => {
          return {
            ...userState,
            isauth: false,
            isadmin: false
          };
        });
        return res;
      });
  };

  const modify = async (payload: TPayload) => {
    return authModify(payload)
      .then(res => {
        setUserState(userState => {
          return {
            ...userState,
            isartist: ((isStatus(res.data) && res.data.isartist) || false),
            isadmin: ((isStatus(res.data) && res.data.isadmin) || false),
            isreset: ((isStatus(res.data) && res.data.isreset) || false),
            artistid: ((isStatus(res.data) && res.data.artistid) || null)
          };
        });
        return res;
      });
  };

  const resetPassword = async (payload: TPayload) => {
    return authResetPassword(payload)
      .then(res => {
        setUserState(userState => {
          return {
            ...userState,
            isauth: false,
            isadmin: false,
            isreset: (res.data===true)
          };
        });
        return res;
      });
  };

  const deleteAccount = async () => {
    return authDeleteAccount()
      .then(res => {
        setUserState(userState => {
          return {
            ...userState,
            isauth: false,
            isadmin: false
          };
        });
        return res;
      });
  };

  return (
    <AuthContext.Provider value={{
      userState,
      websiteState,
      checkStatus,
      login,
      signup,
      logout,
      modify,
      resetPassword,
      deleteAccount,
    }}>
      {children}
    </AuthContext.Provider>
  );
}


export type TAuthContextProvider = {
  userState: TUserState
  websiteState: TWebsiteState
  checkStatus: () => Promise<ReturnPayload<TReturnStatus>>
  login: (payload: TPayload, onSuccess?: Function) => Promise<ReturnPayload<TReturnStatus>>
  signup: (payload: TPayload, onSuccess?: Function) => Promise<ReturnPayload<TReturnStatus>>
  logout: () => Promise<ReturnPayload<TReturn>>
  modify: (payload: TPayload) => Promise<ReturnPayload<TReturnStatus>>
  resetPassword: (payload: TPayload) => Promise<ReturnPayload<TReturn>>
  deleteAccount: () => Promise<ReturnPayload<TReturn>>
}


/**
 * Check that there res.data is a TStatus object
 * @param status the data in res that should be a TStatus
 * @returns a Boolean
 */
function isStatus(status: TReturnStatus | undefined): status is TStatus {
  return status!==undefined && typeof status!=="string";
}
