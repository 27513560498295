import React, { Suspense } from 'react';
import { AuthContextComponent } from 'src/contexts/auth.context';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { DefaultRoute, ProtectedRoute, AdminRoute, ArtistRoute } from './routes';
import { Route, Redirect, RouteProps } from 'react-router-dom';


const MainPage = React.lazy(() => import('./pages/main/main.page'));
const ProductPage = React.lazy(() => import('./pages/product/product.page'));
const ArtistConfirmationPage = React.lazy(() => import('./pages/artistconfirmation/artistconfirmation.page'));
const OrderPage = React.lazy(() => import('./pages/order/order.page'));
const AdminPage = React.lazy(() => import('./pages/admin/admin.page'));
const NotFoundPage = React.lazy(() => import('./pages/misc/404.page'));


function App() {
  return (
    <Router>
      <AuthContextComponent>
        <Suspense fallback={<div></div>}>
          <Switch>
            <Route exact path="/home" render={() => ( <Redirect to="/"/> )}/>
            <DefaultRoute exact path='/' component={MainPage} />
            <DefaultRoute path='/product' component={ProductPage} />
            <ProtectedRoute path='/order' authenticationPath='/' openModal="profile" component={OrderPage} />
            <ArtistRoute path='/artistconfirmation' authenticationPath='/' openModal="profile" component={ArtistConfirmationPage} />
            <AdminRoute path='/admin' authenticationPath='/' component={AdminPage} />
            <DefaultRoute path='/404' component={NotFoundPage} />
          </Switch>
        </Suspense>
      </AuthContextComponent>
    </Router>
  );
}

export default App;
