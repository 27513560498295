import styles from 'src/styles/_variables.module.scss';
import { ArtistInterface } from '../../../src/models/artist.model';
import { ProductInterface } from '../../../src/models/product.model';
import { ORDER_TIMEOUT } from './constants';


const url = process.env.REACT_APP_URL;


export type TSetter<T> = React.Dispatch<React.SetStateAction<T>>;

/**
 * Return the path of the image from the public folder
 * @param imageName (the name of the image)
 * @returns the path or undefined
 */
export function getPublicImageSrc(imageName: string): string | undefined {
  if (imageName && imageName!=="")
    return `${url}/images/${imageName}`;
  return undefined;
}

/**
 * Create a query param from an object
 * @param params (the parameters to use for the query)
 * @returns an encoded string
 */
export function constructQueryParams(params: { [key: string]: any }, parent?: string): string {
  return Object.keys(params).reduce((acc, key) => {
    const sep = (acc.length===0?'':'&');
    const value = params[key];
    if (typeof value==="object")
      return `${acc}${sep}${constructQueryParams(value, key)}`;

    key = (parent?parent+'.'+key:key);
    return `${acc}${sep}${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
  }, "");
}

/**
 * Format a date using the fr-FR convention
 * @param date (a Date or string date)
 * @returns the formatted date as a string
 */
export function formatStringDate(date: Date | string): string {
  return new Intl.DateTimeFormat('fr-FR').format(new Date(date));
}

/**
 * Get the position of the element in the scrolling area under the header
 * @param element (the element we want the position of)
 * @returns the absolute position
 */
export function getElementAbsolutePosition(element: HTMLElement | Element): number {
  const offset = parseInt(styles["layout-titlebar-height"]);
  return window.scrollY + element.getBoundingClientRect().y - offset;
}

/**
 * Converts a string to a key (remove accents, spaces and make lowercase)
 * @param str (the input string)
 * @returns the resulting key
 */
export function formatStringToKey(str: string): string {
  return str.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase().replaceAll(' ', '');
}

/**
 * Converts a string to a readable url component
 * @param str (the input string)
 * @returns the readable url component
 */
export function formatStringToReadableURL(str: string): string {
  str = str.normalize("NFD").replace(/\p{Diacritic}/gu, "");
  str = str.toLowerCase().trim().replaceAll(' ', '-').replaceAll("'", '-');
  return encodeURIComponent(str);
}

/**
 * Format a string spaces to non-breaking spaces
 * @param str (the string to format)
 * @returns the string with only non-breaking spaces
 */
export function formatToNbsp(str: string): string {
  return str.replace(/ /g, "\u00a0");
}

/**
 * Build a string from all classnames and omit undefined
 * @param classNames the class names
 * @returns the string of classes.
 */
export function buildClassName(...classNames: (string | undefined)[]): string {
  return classNames.map(className => className).join(" ");
}

/**
 * Builds the url for the product page
 * @param artist the product artist
 * @param product the product
 * @returns the url as string
 */
export function buildProductPageURL(artist: ArtistInterface | undefined, product: ProductInterface): string {
  const artistname = formatStringToReadableURL(`${artist?.firstname} ${artist?.lastname}`);
  const productname = formatStringToReadableURL(product.name);
  return `/product/${artistname}/${productname}`;
}

/**
 * Get the remaining time to display for order
 * Displays as J-j until we're under 24h left then H-h
 * @param from the date the order was created
 * @returns the formatted time
 */
export function getRemainingDisplayTime(from: Date): string {
  const diff = Date.now() - from.getTime();
  const remainingDays = Math.floor((ORDER_TIMEOUT - diff) / 86400000);
  const remainingHours = Math.floor((ORDER_TIMEOUT - diff) / 3600000);
  return (remainingHours>=24 ?
    `J-${remainingDays}` :
    (remainingHours<0 ? "Expiré" : `H-${remainingHours}`)
  );
}
