import React from 'react';
import { AuthContext } from 'src/contexts/auth.context';
import { Route, Redirect, RouteProps } from 'react-router-dom';


const MaintenancePage = React.lazy(() => import('./pages/misc/maintenance.page'));


export type ProtectedRouteProps = {
  authenticationPath: string
  openModal?: string
} & RouteProps;


export function DefaultRoute({ ...routeProps }: RouteProps) {
  const { userState, websiteState } = React.useContext(AuthContext);
  
  if (!websiteState?.ismaintenance || userState?.isadmin) {
    return <Route {...routeProps} />;
  } else {
    return <>
      {websiteState?.isloading===false &&
        <MaintenancePage />
      }
    </>
  }
};


export function ProtectedRoute({ authenticationPath, openModal, ...routeProps }: ProtectedRouteProps) {
  const { userState, websiteState } = React.useContext(AuthContext);
  
  if (websiteState?.ismaintenance && !userState?.isadmin) {
    return <MaintenancePage />
  } else if (userState?.isauth) {
    return <Route {...routeProps} />;
  } else {
    const state = {...((routeProps.location?.state as any) || {}), openModal};
    return <>
      {userState?.isloading===false &&
        // <Redirect to={{ pathname: authenticationPath, state: {from: routeProps.location, openModal} }} />
        <Redirect to={{ pathname: authenticationPath, state }} />
      }
    </>;
  }
};


export function AdminRoute({ authenticationPath, openModal, ...routeProps }: ProtectedRouteProps) {
  const { userState } = React.useContext(AuthContext);
  
  if (userState?.isauth && userState?.isadmin) {
    return <Route {...routeProps} />;
  } else {
    return <>
      {userState?.isloading===false &&
        <Redirect to={{ pathname: authenticationPath, state: {from: routeProps.location, openModal} }} />
      }
    </>;
  }
};


export function ArtistRoute({ authenticationPath, openModal, ...routeProps }: ProtectedRouteProps) {
  const { userState, websiteState } = React.useContext(AuthContext);
  
  if (websiteState?.ismaintenance && !userState?.isadmin) {
    return <MaintenancePage />
  } else if (userState?.isauth && userState?.isartist) {
    return <Route {...routeProps} />;
  } else {
    return <>
      {userState?.isloading===false &&
        <Redirect to={{ pathname: authenticationPath, state: {from: routeProps.location, openModal} }} />
      }
    </>;
  }
};
